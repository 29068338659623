<script lang="ts">
  import { onMount } from 'svelte';
  import { getDrawerStore } from '@skeletonlabs/skeleton';
  import A from './A.svelte';
  import Title from './Title.svelte';
  import { page } from '$app/stores';
  import { concepts, pro, basics, maps, visualizations } from './sections';
  import skeleton from '$lib/assets/skeleton.svg?raw';
  import mdsvex from '$lib/assets/mdsvex.svg?raw';
  import SearchButton from '../../routes/SearchButton.svelte';
  export let data;
  const drawerStore = getDrawerStore();

  function drawerClose(): void {
    drawerStore.close();
  }

  let nav: HTMLElement;

  onMount(() => {
    nav.addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      if (target && target.tagName == 'A') {
        drawerClose();
      }
    });
  });

  $: active = $page.url.pathname;

  // $: console.log('ddd', $page.data.user);
</script>

<nav class="list-nav p-4" bind:this={nav}>
  <SearchButton id={'search-desktop'} cls="md:hidden mx-4 mb-3" width="w-40" />
  <ul>
    <li><A href="/" ariaLabel="Homepage">Home</A></li>
  </ul>

  <!-- <li><A href="/basic-shapes">Basic Shapes</A></li>
    <li><A href="/bar-chart">Bar Chart</A></li>
    <li><A href="/scatter-chart">Scatter Chart</A></li>
    <li><A href="/line-chart">Line Chart</A></li>
    <li><A href="/pie-chart">Pie Chart</A></li> -->

  <!-- <li><A href="/sponsors">Sponsors</A></li> -->

  <Title id="basics">Pro</Title>
  <ul>
    {#if $page.data?.user}
      {#each pro as basic}
        <li>
          <A href={basic[0]} ariaLabel={basic[2]}
            >{basic[1]}
            {#if basic[3]}
              <span
                class="ml-1 rounded-lg bg-gradient-to-r from-yellow-400 via-orange-300 to-yellow-500 px-1 py-0.5 font-sans text-xs text-gray-800 shadow-md">
                {basic[3]}
              </span>{/if}</A>
        </li>
      {/each}
    {:else}
      <A href={'/login'} ariaLabel="Get Access to pro content">Get Access</A>
      <A href={'/pro-tutorials'} ariaLabel="Get Access to pro content"
        >What's in Pro</A>
      <!-- <li>
          <A href={basic[0]} ariaLabel={basic[2]}
            >{basic[1]}
            {#if basic[3]}
              <span
                class="ml-1 rounded-lg bg-gradient-to-r from-yellow-400 via-orange-300 to-yellow-500 px-1 py-0.5 font-sans text-xs text-gray-800 shadow-md">
                {basic[3]}
              </span>{/if}</A>
        </li> -->
    {/if}
  </ul>
  <Title id="basics">Essentials</Title>

  <ul>
    {#each basics as basic}
      <li>
        <A href={basic[0]} ariaLabel={basic[2]}
          >{basic[1]}
          {#if basic[3]}
            <span
              class="ml-1 rounded-lg bg-gradient-to-r from-yellow-400 via-orange-300 to-yellow-500 px-1 py-0.5 font-sans text-xs text-gray-800 shadow-md">
              {basic[3]}
            </span>{/if}</A>
      </li>
    {/each}
  </ul>

  <Title id="Visualizations">Visualizations</Title>
  <ul>
    {#each visualizations as viz}
      <li>
        <A href={viz[0]} ariaLabel={viz[2]}
          >{viz[1]}
          {#if viz[3]}
            <span
              class="ml-1 rounded-lg bg-gradient-to-r from-yellow-400 via-orange-300 to-yellow-500 px-1 py-0.5 font-sans text-xs text-gray-800 shadow-md">
              {viz[3]}
            </span>{/if}</A>
      </li>
    {/each}
  </ul>

  <Title id="advanced">Advanced</Title>
  <ul>
    <li>
      <A href="/animated-bar-chart"
        >Animated Bar Chart <span
          class="ml-1 rounded-lg bg-gradient-to-r from-yellow-400 via-orange-300 to-yellow-500 px-1 py-0.5 font-sans text-xs text-gray-800 shadow-md">
          {'V5'}
        </span></A>
    </li>
    <li><A href="/creating-data">Create a Bell Curve</A></li>
    <li><A href="/animate-a-bell-curve">Animate a Bell Curve</A></li>
    <li>
      <A href="/beeswarm-chart"
        >Beeswarm Chart <span
          class="ml-1 rounded-lg bg-gradient-to-r from-yellow-400 via-orange-300 to-yellow-500 px-1 py-0.5 font-sans text-xs text-gray-800 shadow-md">
          {'V5'}
        </span></A>
    </li>
  </ul>
  <Title id="maps">Maps</Title>
  <ul>
    {#each maps as map}
      <li>
        <A href={map[0]} ariaLabel={map[2]}
          >{map[1]}
          {#if map[3]}
            <span
              class="ml-2 rounded-xl border bg-gradient-to-r from-yellow-400 via-orange-300 to-yellow-500 px-2 py-0.5 font-sans text-xs text-gray-800 shadow-md">
              {map[3]}
            </span>{/if}</A>
      </li>
    {/each}
  </ul>
  <!-- <Title id="animations">Animations</Title>
  <ul>
    <li><A href="/comingsoon">Coming soon</A></li>
  </ul> -->

  <ul />
  <!-- <Title id="other">Other</Title>
  <ul>
    <li><A href="/du-bois-05">DuBois Challenge</A></li>
  </ul> -->
  <!-- <Title id="concepts">Guides</Title>

      
    <li><A href="/components">Componentization</A></li>
    <li><A href="/crud">CRUD tutorial</A></li>
    <li><A href="/default-values">Default values</A></li>
    <li><A href="/super-debug">SuperDebug</A></li> -->

  <!-- <Title id="concepts">Other topics</Title>

    <li><A href="/contributing">Contribute</A></li>
    <li><A href="/faq">FAQ</A></li>
    <li><A href="/flash-messages">Flash messages</A></li>
    <li><A href="/playground">Playground</A></li>
    <li><A href="/rate-limiting">Rate limiting</A></li>
    <li><A href="/legacy">Legacy version</A></li> -->

  <div class="p-4 text-gray-600">
    <hr class="mb-2 mt-5" />
    Built with<br />
    <a
      aria-label="learn more about skeleton.dev"
      href="https://www.skeleton.dev/"
      target="_blank"
      class="mt-1 !p-0 text-gray-400 hover:!bg-transparent hover:text-gray-300"
      >{@html skeleton}</a>
    <a
      aria-label="learn more about mdsvex"
      href="https://mdsvex.com/"
      target="_blank"
      class="mt-0 w-20 !p-0 text-gray-400 hover:!bg-transparent hover:text-gray-300"
      >{@html mdsvex}</a>
  </div>
</nav>
