export const concepts = [
  ['/concepts/enhance', 'use:enhance'],
  ['/concepts/error-handling', 'Error handling'],
  ['/concepts/messages', 'Status messages'],
  ['/concepts/client-validation', 'Client-side validation'],
  ['/concepts/submit-behavior', 'Submit behavior'],
  ['/concepts/tainted', 'Tainted fields'],
  ['/concepts/events', 'Events'],
  ['/concepts/timers', 'Loading timers'],
  ['/concepts/nested-data', 'Nested data'],
  ['/concepts/multiple-forms', 'Multiple forms'],
  ['/concepts/proxy-objects', 'Proxy objects'],
  ['/concepts/snapshots', 'Snapshots'],
  ['/concepts/spa', 'Single-page apps']
];

export const pro = [
  ['/pro/us-heatmap', 'Animated US MAP', 'Pro Animated US MAP', 'PRO'],
  [
    '/pro/sankey-chart',
    'Animated Sankey Chart',
    'Pro Animated Sankey Chart',
    'PRO'
  ]
];

export const basics = [
  ['/basics/basic-shapes', 'Basic shapes', 'Basic shapes tutorial', 'V5'],
  ['/basics/drawing-svg', 'Drawing on SVG', 'Drawing on SVG tutorial'],
  ['/basics/bar-chart', 'Bar Chart', 'Bar Chart tutorial'],
  [
    '/basics/svelte-5-d3-example',
    'Bar Chart',
    'Svelte 5 and D3 tutorial',
    'V5'
  ],
  [
    '/basics/scatter-chart',
    'Scatter Chart I',
    'Scatter Chart I tutorial',

    'V5'
  ],
  [
    '/basics/scatter-chart-2',
    'Scatter Chart II',
    'Scatter Chart II tutorial',
    'V5'
  ],
  ['/basics/line-chart', 'Line Chart', 'Line Chart tutorial', 'V5'],
  ['/basics/dual-axis', 'Dual Axis', 'Dual Axis tutorial', 'V5'],
  ['/basics/donut-chart', 'Donut Chart', 'Donut Chart tutorial', 'V5'],
  ['/basics/density-plot', 'Density Plot', 'Density Plot tutorial', 'V5'],
  [
    '/basics/stacked-bar-chart',
    'Stacked Bar ',
    'Stacked Bar Chart tutorial',
    'V5'
  ],
  [
    '/basics/line-gradient',
    'Line Gradient',
    'Line with color gradient tutorial',
    'V5'
  ],
  [
    '/basics/force-simulations',
    'Force Directed',
    'For Directed Graph tutorial',
    'V5'
  ]
];

export const maps = [
  ['/maps/us-heatmap', 'US Counties', 'Creating maps with Svelte'],
  [
    '/maps/svelte-threejs-example',
    '3D Mapping',
    'Creating 3D elevation maps with Svelte'
  ]
];

export const visualizations = [
  [
    '/visualizations/area-chart',
    'Area Chart',
    'Area Chart with Svelte 5 tutorial',
    'V5'
  ],
  [
    '/visualizations/sankey-chart',
    'Sankey Chart',
    'Sankey chart with Svelte 5 tutorial',
    'V5'
  ]
];
