<script lang="ts">
  import { page } from '$app/stores';

  export let href: string;
  export let ariaLabel: string;
  $: current = $page.url.pathname == href;
</script>

<a
  class:bg-primary-active-token={current}
  class="flex items-center font-medium"
  {href}
  aria-label={ariaLabel}
  {...$$props}>
  <slot />
</a>
